<template>
  <div id="car-row-login">
    <div class="loginbox">
      <div class="titlebox">
        <span>{{shop_name||''}}</span>
        <span v-if="guard_name=='personnel_manger_user'">{{type==1?'站点':'商家'}}</span>
        <span>登录后台</span>
      </div>

      <div class="inputbox" style="margin-bottom: 40px;">
        <div class="label">登录账号</div>
        <el-input type="text" style="input" v-model="user_name" placeholder="请输入账号"></el-input>
      </div>

      <div class="inputbox">
        <div class="label">登录密码</div>
        <el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
      </div>

      <el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
    </div>
  </div>
</template>

<script>
import { eltips, getUrlKey } from '@/util/util.js';
import { resetRouter } from '@/router/index.js'
import store from '@/store/index.js'
export default {
  name: 'car-row-login',
  data() {
    return {
      shop_name: '',
      user_name: '',//用户名
      password: '',//密码
      type: '', //1、站点 2、商家
      guard_name: '',//应用名称
      shop_id: '',
      token: ''
    };
  },
  created() {
    if (getUrlKey('type')) {
      this.type = getUrlKey('type')
    }

    if (getUrlKey('name')) {
      this.shop_name = getUrlKey('name')
    }

    if (getUrlKey('shop_id')) {
      this.shop_id = getUrlKey('shop_id')
    }

    if (getUrlKey('guard_name')) {
      this.guard_name = getUrlKey('guard_name')
      sessionStorage.setItem('guardName', getUrlKey('guard_name'))
    }
  },
  methods: {
    //登录
    login() {
      if (!this.user_name) {
        eltips('请输入账号', 'error')
        return
      }
      if (!this.password) {
        eltips('请输入密码', 'error')
        return
      }

      let data = {
        shop_id: this.shop_id,
        account: this.user_name,
        password: this.password
      }
      // if(this.guard_name){
      // 	data.guard_name = this.guard_name
      // }
      // if(this.type){
      // 	data.type = this.type
      // }

      this.axios.post('/api/car/admin/shop_login', data).then(res => {
        if (res.code == 200) {
          sessionStorage.setItem('token', res.data)
          sessionStorage.removeItem('role_id')
          sessionStorage.removeItem('pluginroute')
          sessionStorage.removeItem('pluginid')
          this.getuserinfo()
        } else {
          eltips(res.msg, 'error')
        }
      })
    },
    //获取个人信息
    getuserinfo() {
      this.axios.get('/api/me', {
        params: {
          guard_name: this.guard_name
        }
      }).then(res => {
        if (res.code == 200) {
          sessionStorage.setItem('userinfo', JSON.stringify(res.data))
          this.getmeuns()
        }
      })
    },
    //获取权限菜单
    getmeuns() {

      let routs = [
        {
          "id": 2891,
          "name": "员工",
          "desc": null,
          "guard_name": "car_market",
          "route": "",
          "uri": null,
          "icon": 'yuangong',
          "parent_id": 2891,
          "sort": 2,
          "type": 1,
          "unitid": 39,
          "path": "/carShop/staffAdmin",
          "vue_name": "staffAdmin",
          "component": "apps/automall/carShop/staffAdmin.vue",
          "router_name": "carShop",
          "layout": "1",
          "status": 1,
          "props": null,
          "created_at": null,
          "updated_at": null,
          "app_level": 0,
          "project_app_id": 0,
          "category_id": 0,
          "is_apportion": 1,
          "keep_alive": 0,
          "_child": []
        },
        {
          "id": 2907,
          "name": "车辆",
          "desc": null,
          "guard_name": "car_market",
          "route": "",
          "uri": null,
          "icon": "cheliang",
          "parent_id": 0,
          "sort": 4,
          "type": 1,
          "unitid": 39,
          "path": "/vehicle/carAdmin",
          "vue_name": null,
          "component": "apps/automall/vehicle/carAdmin.vue",
          "router_name": "vehicle",
          "layout": "1",
          "status": 1,
          "props": null,
          "created_at": null,
          "updated_at": null,
          "app_level": 0,
          "project_app_id": 0,
          "category_id": 0,
          "is_apportion": 1,
          "keep_alive": 0,
          "_child": []
        },
        {
          "id": 2908,
          "name": "新增车辆",
          "desc": null,
          "guard_name": "car_market",
          "route": "",
          "uri": null,
          "icon": null,
          "parent_id": 2907,
          "sort": 4,
          "type": 1,
          "unitid": 39,
          "path": "/vehicle/addcar",
          "vue_name": "addcar",
          "component": "apps/automall/vehicle/addcar.vue",
          "router_name": "vehicle",
          "layout": "2",
          "status": 1,
          "props": null,
          "created_at": null,
          "updated_at": null,
          "app_level": 0,
          "project_app_id": 0,
          "category_id": 0,
          "is_apportion": 1,
          "keep_alive": 0,
          "_child": []
        },
        {
          "id": 2909,
          "name": "ERP",
          "desc": null,
          "guard_name": "car_market",
          "route": "",
          "uri": null,
          "icon": "ERP",
          "parent_id": 0,
          "sort": 4,
          "type": 1,
          "unitid": 39,
          "path": "",
          "vue_name": null,
          "component": "Layout",
          "router_name": "erp",
          "layout": "1",
          "status": 1,
          "props": null,
          "created_at": null,
          "updated_at": null,
          "app_level": 0,
          "project_app_id": 0,
          "category_id": 0,
          "is_apportion": 1,
          "keep_alive": 0,
          "_child": [
            {
              "id": 2910,
              "name": "采购线索",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2909,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/erp/purchaseLead",
              "vue_name": "purchaseLead",
              "component": "apps/automall/erp/purchaseLead.vue",
              "router_name": "erp",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2911,
              "name": "评估管理",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2909,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/erp/assessment",
              "vue_name": "assessment",
              "component": "apps/automall/erp/assessment.vue",
              "router_name": "erp",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2912,
              "name": "采购订单",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2909,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/erp/purchaseOrder",
              "vue_name": "purchaseOrder",
              "component": "apps/automall/erp/purchaseOrder.vue",
              "router_name": "erp",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2913,
              "name": "库存管理",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2909,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/erp/inventory",
              "vue_name": "inventory",
              "component": "apps/automall/erp/inventory.vue",
              "router_name": "erp",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2914,
              "name": "销售订单",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2909,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/erp/salesOrder",
              "vue_name": "salesOrder",
              "component": "apps/automall/erp/salesOrder.vue",
              "router_name": "erp",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },]
        },
        {
          "id": 2915,
          "name": "财务",
          "desc": null,
          "guard_name": "car_market",
          "route": "",
          "uri": null,
          "icon": "caiwu",
          "parent_id": 0,
          "sort": 4,
          "type": 1,
          "unitid": 39,
          "path": "",
          "vue_name": null,
          "component": "Layout",
          "router_name": "finance",
          "layout": "1",
          "status": 1,
          "props": null,
          "created_at": null,
          "updated_at": null,
          "app_level": 0,
          "project_app_id": 0,
          "category_id": 0,
          "is_apportion": 1,
          "keep_alive": 0,
          "_child": [
            {
              "id": 2916,
              "name": "支付记录",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2915,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/finance/paymentList",
              "vue_name": "paymentList",
              "component": "apps/automall/finance/paymentList.vue",
              "router_name": "finance",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2917,
              "name": "收入记录",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2915,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/finance/incomeList",
              "vue_name": "incomeList",
              "component": "apps/automall/finance/incomeList.vue",
              "router_name": "finance",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            },
            {
              "id": 2918,
              "name": "资金账户",
              "desc": null,
              "guard_name": "car_market",
              "route": "",
              "uri": null,
              "icon": null,
              "parent_id": 2915,
              "sort": 0,
              "type": 1,
              "unitid": 39,
              "path": "/finance/capitalList",
              "vue_name": "capitalList",
              "component": "apps/automall/finance/capitalList.vue",
              "router_name": "finance",
              "layout": "1",
              "status": 1,
              "props": null,
              "created_at": null,
              "updated_at": null,
              "app_level": 0,
              "project_app_id": 0,
              "category_id": 0,
              "is_apportion": 1,
              "keep_alive": 0,
              "_child": []
            }]
        },
      ]

      resetRouter()
      this.$store.commit('routestatus', 0)
      sessionStorage.removeItem('routes')
      sessionStorage.removeItem('routestext')
      sessionStorage.setItem('routes', JSON.stringify(routs))
      let res = sessionStorage.getItem("routes")
      if (res) {
        this.$router.replace({ path: "/carShop/staffAdmin" })
      }
    },
  }
};
</script>


<style>
#car-row-login .el-input__inner {
  border: none !important;
}

#car-row-login .el-button {
  padding: 18px 20px !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped lang="less">
#car-row-login {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: url(../../assets/images/login_bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  .loginbox {
    width: 380px;
    background: #ffffff;
    border-radius: 20px;
    padding: 30px;
  }
  .titlebox {
    font-size: 20px;
    color: #1e92ff;
    margin-bottom: 40px;
    font-weight: bold;
  }
  .inputbox {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 6px;
    .label {
      width: 80px;
    }
    .input {
    }
  }
  .loginbtn {
    margin-top: 160px;
    width: 100%;
  }
}
</style>
